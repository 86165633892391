@font-face {
    font-family: 'brandon';
    font-size: 400;
    src: local('brandon'), url('./fonts/brandon-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'brandon-medium';
    font-size: 600;
    src: local('brandon-medium'), url('./fonts/brandon-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'brandon-bold';
    font-size: 800;
    src: local('brandon-bold'), url('./fonts/brandon-bold.woff2') format('woff2');
}